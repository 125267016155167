.logo {
    height: 30px;
    width: 30px;
}

.customTable {
    margin: 0 auto; /* or margin: 0 auto 0 auto */
}

.link {
    margin: 0 auto;
}

.linkDesc {
    position: absolute;
    margin-top: 6px;
}