h3 {
    color: gray;
}

.inputBox{
    width: 70%
}

.parentContainer {
    width: 100%;
    display: flex;
    margin-left: 5%;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.titleContainer {
    margin: 0 auto;
    display: block;
}

.mainContainer {
    flex: 50%;
    align-items: center;
    display: block;
}

.error {
    background-color: #902c3e;
    color: white;
    margin: 10px 10px 10px 10px;
}

.inputContainer {
    padding-top: 10px;
}
