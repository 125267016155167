.titleContainer {
    margin: 0 auto;
    display: block;
}

.mainContainer {
    margin-left: 200
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}